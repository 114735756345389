<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './siteAdminProfileFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'site-admin-profile-form',
    meta: {},
  }),
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.loadSiteAdminRoles(),
      ])
    },
    async loadSiteAdminRoles() {
      const result = await this.$api.collection.siteAdminApi.roles()
      this.meta.roles = result.roles
    },
    async readApi() {
      let result = await this.$api.collection.siteAdminAccountApi.readProfile()
      result.roles = this.$eagleLodash.cloneDeep(this.tokenRoles)
      return result
    },
    async updateApi(target, formData) {
      return await this.$api.collection.siteAdminAccountApi.updateProfile(formData)
    },
    getFormConfig() {
      return formConfig
    },
    afterSaveSuccessCallback(result) {
      this.refreshToken()
    },
    async refreshToken() {
      try {
        const result = await this.$api.collection.siteAdminAccountApi.renewToken(true)
        this.$tokenStore.set(result.token)
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    tokenRoles() {
      return this.$store.getters['token/roles']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
